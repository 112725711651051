import { Injectable } from '@angular/core';
import { BuvService } from './buv.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BUVLogin, User } from '../interfaces/user.interface';
import { JWTPayload } from '../interfaces/jwtPayload';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  group$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  roles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  userLogged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _buvService: BuvService) {
    const token = this.getToken();
    if (token) {
      this.userLogged$.next(true);
      this.getUser();
    }
  }

  buvLogin(user: string, password: string): Observable<BUVLogin> {
    const body = {
      usuario: user,
      clave: password,
    };
    return this._buvService.buvLogin(body).pipe(
      map((resp) => {
        this.userLogged$.next(true);
        if (resp.JWT) {
          const payload: JWTPayload = this._parseJwt(resp.JWT);
          this.roles$.next(payload.realm_access.roles);
          this.group$.next(resp.group);
          this.setToken(resp.JWT);
        }
        if (resp.user) {
          this.setUser(resp.user);
          this.user$.next(resp.user);
        }
        return resp;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.user$.next(null);
  }

  interceptorSetTokenHttp(): string {
    return this.getToken();
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): User | null {
    const userLocalStorage = localStorage.getItem('user');
    if (!userLocalStorage) return null;
    this.user$.next(JSON.parse(localStorage.getItem('user')!));
    return JSON.parse(localStorage.getItem('user')!);
  }

  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  isAuthenticated(): boolean {
    return this.getToken() ? true : false;
  }

  private _parseJwt(token: string): JWTPayload {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
