import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { max, min } from 'rxjs';

@Pipe({
  name: 'formErrors',
  standalone: true,
})
export class FormErrorsPipe implements PipeTransform {
  constructor() {}

  transform(control: ValidationErrors | null): string {
    if (control) {
      const errorType: string = Object.keys(control)[0];
      switch (errorType) {
        case 'min':
          const minValue = control['min']['min'].toString().length;
          return `Debe ingresar un valor con al menos ${minValue} caracteres`;
        case 'max':
          const maxValue = control['max']['max'].toString().length;
          return `Debe ingresar un valor con no más de ${maxValue} caracteres`;
        case 'email':
          return `Debe ingresar un correo electronico`;
        default:
          return 'Debe ingresar un valor.';
      }
      // const limitOfCharacters =
      // 	control.maxlength?.requiredLength || control.minlength?.requiredLength || 0;
      // const limitOfValue = control.max?.max || control.min?.min || null;
    }
    return '';
    // const errors = this.globalConstants.msjErrorFrom;
    // if (control) {
    // 	const limitOfCharacters =
    // 		control.maxlength?.requiredLength || control.minlength?.requiredLength || 0;
    // 	const limitOfValue = control.max?.max || control.min?.min || null;
    // 	const errorType: string = Object.keys(control)[0];
    // 	const errorMsg = errors[errorType as keyof typeof errors];
    // 	if (limitOfCharacters > 0) {
    // 		return `${this.translateService.instant(errorMsg)}${limitOfCharacters}`;
    // 	}
    // 	if (limitOfValue) {
    // 		return `${this.translateService.instant(errorMsg)}${limitOfValue}`;
    // 	}
    // 	return this.translateService.instant(errorMsg);
    // }
    // return '';
  }
}
